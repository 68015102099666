import { registerApplication, RegisterApplicationConfig, start } from "single-spa";

const config: RegisterApplicationConfig = {
  name: '@juno/layout-spa',
  app: () => System.import('@juno/layout-spa'),
  activeWhen: '/',
  customProps: {
    appName: 'juno'
  }
};

registerApplication(config)

start({
  urlRerouteOnly: true,
});
